<script>
  import { parseISO } from "date-fns";
  import { onDestroy, onMount, tick } from "svelte";
  import { _, locale } from "svelte-i18n";

  import { ShippingStatus } from "~/libs/commonTypes";
  import {
    NO_PICKUP_TIMEFRAME,
    RedeliveryDatetimeSpecMethod,
  } from "~/libs/commonTypes";
  import { formatDate } from "~/libs/dateUtils";
  import { formatTrackingNumber } from "~/libs/utils";
  import AdditionalInfoDialog from "~/pages/Tracking/AdditionalInfoDialog.svelte";
  import IdentificationDialog from "~/pages/Tracking/IdentificationDialog.svelte";
  import PackageDropPlaceInfoDialog from "~/pages/Tracking/PackageDropPlaceInfoDialog.svelte";
  import SetAvailablePickupDatetime from "~/pages/Tracking/SetAvailablePickupDatetime.svelte";
  import SetDesiredRedeliveryDatetime from "~/pages/Tracking/SetDesiredRedeliveryDatetime.svelte";
  import TrackingEvent from "~/pages/Tracking/TrackingEvent.svelte";
  import Trouble from "~/pages/Tracking/Trouble.svelte";

  /** @type {number} */
  export let order;
  /** @type {string} */
  export let trackingNumber;
  /** @type {import("~/libs/commonTypes").TrackingResult} */
  export let result;

  /** @type {number} */
  let latestEventStatus;
  /** @type {string} */
  let deliveryDate;
  /** @type {string} */
  let deliveryTime;
  /** @type {string} */
  let deriveryDateLabel;
  /** @type {string} */
  let redeliveryDateLabel;
  /** @type {string} */
  let redeliveryDate;
  /** @type {string} */
  let redeliveryTime;
  /** @type {string} */
  let redeliveryBtnLabel;

  /** 本人確認による追加情報取得ダイアログの開閉フラグ @type {boolean} */
  let openIdentificationDialog = false;
  /** @type {boolean} */
  let openAdditionalInfoDialog = false;
  /** @type {boolean} */
  let openPackageDropPlaceInfoDialog = false;
  /** @type {SetDesiredRedeliveryDatetime} */
  let setDesiredRedeliveryDatetime;
  /** @type {SetAvailablePickupDatetime} */
  let setAvailablePickupDatetime;
  /** @type {Function} */
  let functionAfterIdentification = null;

  /** @type {import("svelte/store").Unsubscriber} */
  let localeUnsubscriber;

  onMount(async () => {
    result.events.sort((first, second) =>
      first.time.localeCompare(second.time),
    );
    latestEventStatus = result.events[result.events.length - 1].status;

    configureLocaleSpecificFormat();
    localeUnsubscriber = locale.subscribe(() => {
      configureLocaleSpecificFormat();
    });
  });

  onDestroy(() => {
    localeUnsubscriber && localeUnsubscriber();
  });

  const configureLocaleSpecificFormat = function () {
    if (latestEventStatus == ShippingStatus.DELIVERED) {
      deriveryDateLabel = $_(
        "pages.Tracking.TrackingResult.deliveredDateLabel",
      );
      deliveryDate = formatDate(
        result.events[result.events.length - 1].time,
        $_("config.defaultDateFormat"),
        $locale,
      );
    } else {
      deriveryDateLabel = $_("pages.Tracking.TrackingResult.desiredDateLabel");

      if (result.desiredDate) {
        deliveryDate = formatDate(
          result.desiredDate,
          $_("config.defaultDateFormat"),
          $locale,
        );
        deliveryTime = result.desiredTime
          ? formatDesiredTime(result.desiredTime)
          : $_("pages.Tracking.TrackingResult.desiredTimeUnspecified");
      } else if (result.desiredTime) {
        deliveryDate = $_(
          "pages.Tracking.TrackingResult.desiredDateUnspecified",
        );
        deliveryTime = formatDesiredTime(result.desiredTime);
      } else {
        deliveryDate = $_(
          "pages.Tracking.TrackingResult.desiredDateTimeUnspecified",
        );
      }
    }
    if (result.redeliveryContext?.adjustedRedeliveryDatetime) {
      // 電話で調整済み
      redeliveryDateLabel = $_(
        "pages.Tracking.TrackingResult.redeliveryAdjustedDateLabel",
      );
      redeliveryDate = formatDate(
        new Date(result.redeliveryContext.adjustedRedeliveryDatetime.date),
        $_("config.defaultDateFormat"),
        $locale,
      );
      redeliveryTime =
        result.redeliveryContext.adjustedRedeliveryDatetime.timeFrame ===
        NO_PICKUP_TIMEFRAME
          ? $_("pages.Tracking.TrackingResult.redeliveryDateTimeUnspecified")
          : formatDesiredTime(
              result.redeliveryContext.adjustedRedeliveryDatetime.timeFrame,
            );
      redeliveryBtnLabel = $_(
        "pages.Tracking.TrackingResult.redeliveryDesiredChangeButtonLabel",
      );
    } else if (
      result.redeliveryContext?.redeliveryDatetimeSpecMethod ===
      RedeliveryDatetimeSpecMethod.DESIRED_DATE_AND_TIME
    ) {
      // 指定日時を1つ指定
      redeliveryDateLabel = $_(
        "pages.Tracking.TrackingResult.redeliveryDesiredDateLabel",
      );
      if (result.specifiedPickupDatetime?.desiredRedeliveryDatetime) {
        // 荷受人による指定済み
        if (
          result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame !==
          NO_PICKUP_TIMEFRAME
        ) {
          // 希望日時あり
          redeliveryDate = formatDate(
            new Date(
              result.specifiedPickupDatetime.desiredRedeliveryDatetime.date,
            ),
            $_("config.defaultDateFormat"),
            $locale,
          );
          redeliveryTime = formatDesiredTime(
            result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame,
          );
        } else {
          // 受け取り可能な時間帯なし
          if ($locale === "ja") {
            redeliveryDate =
              formatDate(
                new Date(
                  result.specifiedPickupDatetime.desiredRedeliveryDatetime.date,
                ),
                $_("config.defaultDateFormat"),
                $locale,
              ) +
              $_("pages.Tracking.TrackingResult.redeliveryAllUnavailableUntil");
          } else {
            redeliveryDate =
              $_(
                "pages.Tracking.TrackingResult.redeliveryAllUnavailableUntil",
              ) +
              formatDate(
                new Date(
                  result.specifiedPickupDatetime.desiredRedeliveryDatetime.date,
                ),
                $_("config.defaultDateFormat"),
                $locale,
              );
          }

          redeliveryTime = $_(
            "pages.Tracking.TrackingResult.redeliveryAllUnavailable",
          );
        }
        redeliveryBtnLabel = $_(
          "pages.Tracking.TrackingResult.redeliveryDesiredChangeButtonLabel",
        );
      } else {
        // 荷受人による指定がまだ
        redeliveryBtnLabel = $_(
          "pages.Tracking.TrackingResult.redeliveryDesiredRegistButtonLabel",
        );
      }
    } else if (
      result.redeliveryContext?.redeliveryDatetimeSpecMethod ===
      RedeliveryDatetimeSpecMethod.AVAILABLE_RECEIVE_TIME_ZONE_OF_WEEK
    ) {
      // 1週間分の受け取り可能時間帯を指定
      redeliveryDateLabel = $_(
        "pages.Tracking.TrackingResult.redeliveryAvailablePickupLabel",
      );
      if (result.specifiedPickupDatetime?.availablePickupDatetime) {
        // 荷受人による指定済み
        redeliveryDate = "";
        redeliveryTime = "";

        // 受け取り可能時間の指定を逆からたどり、時間指定がある("0000"ではない)場合に日付、時間を取得
        [...result.specifiedPickupDatetime.availablePickupDatetime]
          .reverse()
          .forEach((dateAndTimeFrame) => {
            const today = new Date();
            today.setHours(0, 0, 0);
            if (
              dateAndTimeFrame.timeFrame !== NO_PICKUP_TIMEFRAME &&
              new Date(dateAndTimeFrame.date) >= today
            ) {
              redeliveryDate = formatDate(
                new Date(dateAndTimeFrame.date),
                $_("config.defaultDateFormat"),
                $locale,
              );
              redeliveryTime =
                formatDesiredTime(dateAndTimeFrame.timeFrame) +
                $_("pages.Tracking.TrackingResult.redeliveryOther");
            }
          });

        if (redeliveryDate === "") {
          // 全日、受け取り不可
          if ($locale === "ja") {
            redeliveryDate =
              formatDate(
                new Date(
                  result.specifiedPickupDatetime.availablePickupDatetime[
                    result.specifiedPickupDatetime.availablePickupDatetime
                      .length - 1
                  ].date,
                ),
                $_("config.defaultDateFormat"),
                $locale,
              ) +
              $_("pages.Tracking.TrackingResult.redeliveryAllUnavailableUntil");
          } else {
            redeliveryDate =
              $_(
                "pages.Tracking.TrackingResult.redeliveryAllUnavailableUntil",
              ) +
              formatDate(
                new Date(
                  result.specifiedPickupDatetime.availablePickupDatetime[
                    result.specifiedPickupDatetime.availablePickupDatetime
                      .length - 1
                  ].date,
                ),
                $_("config.defaultDateFormat"),
                $locale,
              );
          }

          redeliveryTime = $_(
            "pages.Tracking.TrackingResult.redeliveryAllUnavailable",
          );
        }
        redeliveryBtnLabel = $_(
          "pages.Tracking.TrackingResult.redeliveryAvailablePickUpChangeButtonLabel",
        );
      } else {
        // 荷受人による設定がまだ
        redeliveryBtnLabel = $_(
          "pages.Tracking.TrackingResult.redeliveryAvailablePickUpRegistButtonLabel",
        );
      }
    }
    if (
      redeliveryDate ===
      formatDate(new Date(), $_("config.defaultDateFormat"), $locale)
    ) {
      redeliveryDate =
        $_("pages.Tracking.TrackingResult.redeliveryToday") + redeliveryDate;
    }
  };

  /**
   * 配送希望時間をフォーマットする
   * @param {string} desiredTime 数字4桁（開始時間2桁、終了時間2桁）
   * @returns {string} 時間指定の表示
   */
  function formatDesiredTime(desiredTime) {
    let startTime = Number(desiredTime.substring(0, 2));
    let endTime = Number(desiredTime.substring(2, 4));

    let str = "";

    if ($locale === "ja") {
      if (startTime !== 0) {
        str = startTime + "時";
      }
      str = str + "～";
      if (endTime !== 0) {
        str = str + endTime + "時";
      }
    } else {
      let startTimeZone = "AM";
      let endTimeZone = "AM";

      if (startTime > 12) {
        startTime = startTime - 12;
        startTimeZone = "PM";
      }
      if (endTime > 12) {
        endTime = endTime - 12;
        endTimeZone = "PM";
      }

      if (startTime !== 0) {
        str = startTime + ":00 " + startTimeZone;
      }
      str = str + " - ";
      if (endTime !== 0) {
        str = str + endTime + ":00 " + endTimeZone;
      }
    }
    return str;
  }

  /**
   * 現時刻との日時の比較を行う
   * @param {string|Date} date 配送希望日。Date型オブジェクトもしくはISO 8601形式（Tは半角スペースでも可）の日付文字列
   * @param {string} time 配送希望時間の終わり
   * @returns {boolean} date, timeが現時刻より前の場合true, 現時刻以降の場合false
   */
  function beforeToday(date, time) {
    const today = new Date();
    let parsedDate;

    if (date instanceof Date) {
      parsedDate = date;
    } else if (typeof date === "string") {
      parsedDate = parseISO(date.replace(" ", "T"));
    }
    if (time !== "") {
      parsedDate.setHours(Number(time));
    } else {
      parsedDate.setHours(23, 59);
    }

    return parsedDate < today;
  }
</script>

<AdditionalInfoDialog bind:open={openAdditionalInfoDialog} {result} />

<PackageDropPlaceInfoDialog
  bind:open={openPackageDropPlaceInfoDialog}
  {result}
/>

{#if result.redeliveryContext?.redeliveryDatetimeSpecMethod === RedeliveryDatetimeSpecMethod.DESIRED_DATE_AND_TIME}
  <SetDesiredRedeliveryDatetime
    bind:this={setDesiredRedeliveryDatetime}
    {trackingNumber}
    {result}
    {configureLocaleSpecificFormat}
  />
{:else if result.redeliveryContext?.redeliveryDatetimeSpecMethod === RedeliveryDatetimeSpecMethod.AVAILABLE_RECEIVE_TIME_ZONE_OF_WEEK}
  <SetAvailablePickupDatetime
    bind:this={setAvailablePickupDatetime}
    {trackingNumber}
    {result}
    {configureLocaleSpecificFormat}
  />
{/if}

<div class="caption">
  <p class="captionText">
    {$_("pages.Tracking.TrackingResult.captionPrefix", {
      values: { order: order },
    })}{formatTrackingNumber(trackingNumber)}
  </p>
</div>
<div class="trackingInfo">
  {#if result.extraEvents || result.return}
    <div class="troubleArea">
      <div class="troubleInfo">
        {#if result.extraEvents}
          {#each result.extraEvents as trouble, i}
            {#if result.return && result.return.time < trouble.time && (i === 0 || result.extraEvents[i - 1].time <= result.return.time)}
              <Trouble returnInfo={result.return} />
            {/if}
            <Trouble {trouble} />
          {/each}
        {/if}
        {#if result.return && (!result.extraEvents || result.extraEvents[result.extraEvents.length - 1].time < result.return.time)}
          <Trouble returnInfo={result.return} />
        {/if}
      </div>
    </div>
  {/if}
  <div class="latestStatusArea">
    <div class="item">
      <div class:itemName1={!result.url} class:itemName2={result.url}>
        {$_("pages.Tracking.TrackingResult.statusLabel")}
      </div>
      <div class:itemInfo1={!result.url} class:itemInfo2={result.url}>
        <div>
          {#if !result.return}
            {$_(`messages.status.${latestEventStatus}`)}
          {:else}
            {$_(`messages.status.return`)}
          {/if}

          {#if !result.return && result.damaged}
            <span class="caution"
              >{$_("pages.Tracking.TrackingResult.cautionMark")}</span
            >
          {/if}
        </div>

        {#if latestEventStatus === ShippingStatus.DELIVERED && !Number.isInteger(result.companyId)}
          <div class="adjustment1" />
          <label class="changeButton">
            <input
              type="button"
              class="hideButton"
              on:click={() => (openIdentificationDialog = true)}
            />
            <div class="funcTitle1">
              <span class="material-icons md-light md-18">info_outline</span>
              <p>
                {$_("pages.Tracking.TrackingResult.showDetailsButtonLabel")}
              </p>
            </div>
          </label>
        {:else if !result.return && result.url}
          <div class="adjustment1" />
          <label class="changeButton">
            <input
              type="button"
              class="hideButton"
              on:click={() => (openAdditionalInfoDialog = true)}
            />
            <div class="funcTitle1">
              <span class="material-icons md-light md-18">photo_camera</span>
              <p>
                {$_("pages.Tracking.TrackingResult.showPictureButtonLabel")}
              </p>
            </div>
          </label>
        {/if}
      </div>
    </div>
    {#if !result.return}
      <div class="vline" />
      {#if !result.isExistsRedeliveryContext || latestEventStatus === ShippingStatus.DELIVERED}
        <!-- 再配達申請待ちでない、もしくは配達完了済みの場合 -->
        <div class="item">
          <div class="itemName1">
            <div>
              {deriveryDateLabel}
              {#if latestEventStatus !== ShippingStatus.DELIVERED && result.desiredDate && beforeToday(result.desiredDate, result.desiredTime ? result.desiredTime.substring(2, 4) : "")}
                <span class="caution" style="font-weight: bold;"
                  >{$_("pages.Tracking.TrackingResult.cautionMark")}</span
                >
              {/if}
            </div>
          </div>
          <div class="itemInfo1">
            <span>
              {deliveryDate}
            </span>
            {#if deliveryTime}
              <span style="margin-top: 5px">{deliveryTime}</span>
            {/if}
          </div>
        </div>
      {:else if result.redeliveryContext}
        <!-- 再配達申請待ち(再配達コンテキスト取得済み)の場合 -->
        <div class="item">
          <div class="itemName2">
            <div>
              {redeliveryDateLabel}
              <span class="caution" style="font-weight: bold;"
                >{$_("pages.Tracking.TrackingResult.cautionMark")}</span
              >
            </div>
          </div>
          <div class="itemInfo2">
            {#if redeliveryDate}
              <span>
                {redeliveryDate}
              </span>
              {#if redeliveryTime}
                <span>
                  {redeliveryTime}
                </span>
              {/if}
            {:else}
              <span
                >{$_(
                  "pages.Tracking.TrackingResult.redeliveryDateTimeUnspecified",
                )}</span
              >
            {/if}
            <div class="adjustment1" />
            <label class="changeButton">
              <input
                type="button"
                class="hideButton"
                on:click={() => {
                  if (
                    result.redeliveryContext.redeliveryDatetimeSpecMethod ===
                    RedeliveryDatetimeSpecMethod.DESIRED_DATE_AND_TIME
                  ) {
                    setDesiredRedeliveryDatetime.openDialog();
                  } else {
                    setAvailablePickupDatetime.openDialog();
                  }
                }}
              />
              <div class="funcTitle1">
                <span class="material-icons md-light md-18">date_range</span>
                <p>{redeliveryBtnLabel}</p>
              </div>
            </label>
          </div>
        </div>
      {:else if result.isExistsRedeliveryContext}
        <!-- 再配達申請待ち(再配達コンテキスト未取得)の場合 -->
        <div class="item">
          <div class="itemName2">
            <div>
              {$_("pages.Tracking.TrackingResult.redeliveryAdjustedDateLabel")}
            </div>
          </div>
          <div class="itemInfo2">
            <label class="changeButton bigSizeButton">
              <input
                type="button"
                class="hideButton"
                on:click={() => {
                  functionAfterIdentification = async () => {
                    configureLocaleSpecificFormat();
                    await tick();
                    if (
                      result.redeliveryContext.redeliveryDatetimeSpecMethod ===
                      RedeliveryDatetimeSpecMethod.DESIRED_DATE_AND_TIME
                    ) {
                      setDesiredRedeliveryDatetime.openDialog();
                    } else {
                      setAvailablePickupDatetime.openDialog();
                    }
                  };
                  openIdentificationDialog = true;
                }}
              />
              <div class="funcTitle1">
                <span class="material-icons md-light md-18">local_shipping</span
                >
                <p>
                  {$_(
                    "pages.Tracking.TrackingResult.redeliveryRequestButtonLabel",
                  )}
                </p>
              </div>
            </label>
          </div>
        </div>
      {/if}
    {/if}
    <!-- トークンありの場合のみ表示(companyIdの有無で判定) -->
    {#if !result.return && Number.isInteger(result.companyId)}
      <div class="vline" />
      <div class="item">
        <div
          class={!Number.isInteger(result.actualPackageDropPlace) ||
          result.deliveryBoxNumber ||
          result.deliveryBoxPin
            ? "itemName2"
            : "itemName1"}
        >
          {$_("pages.Tracking.TrackingResult.dropOffLocationLabel")}
        </div>
        <div
          class={!Number.isInteger(result.actualPackageDropPlace) ||
          result.deliveryBoxNumber ||
          result.deliveryBoxPin
            ? "itemInfo2"
            : "itemInfo1"}
        >
          <div class="infoButtonArea">
            {#if Number.isInteger(result.actualPackageDropPlace) && result.packageDropPlace !== result.actualPackageDropPlace}
              {$_(`messages.packageDropPlace.${result.actualPackageDropPlace}`)}
              {#if Number.isInteger(result.packageDropPlace)}
                <button
                  class="material-icons mainColor packageDropPlaceInfoBtn"
                  on:click={() => (openPackageDropPlaceInfoDialog = true)}
                  >error</button
                >
              {/if}
            {:else}
              {$_(`messages.packageDropPlace.${result.packageDropPlace}`)}
            {/if}
          </div>
          {#if result.deliveryBoxNumber || result.deliveryBoxPin}
            <div class="adjustment1" />
            <label class="changeButton">
              <input
                type="button"
                class="hideButton"
                on:click={() => {
                  openAdditionalInfoDialog = true;
                }}
              />
              <div class="funcTitle2">
                <span class="material-icons md-light md-18">lock</span>
                <p>
                  {$_(
                    "pages.Tracking.TrackingResult.showDeliveryBoxDetailButtonLabel",
                  )}
                </p>
              </div>
            </label>
          {/if}
        </div>
      </div>
    {/if}
    {#if result.return}
      <div class="vline" />
      <div class="item">
        <div class="itemName1">
          {$_("pages.Tracking.TrackingResult.returnReasonLabel")}
        </div>
        <div class="itemInfo1">
          {@html $_(`messages.returnReason.${result.return.returnReason}`)}
        </div>
      </div>
    {/if}

    <!-- 認証ダイアログ -->
    {#key openIdentificationDialog}
      <IdentificationDialog
        bind:open={openIdentificationDialog}
        {trackingNumber}
        bind:result
        bind:functionAfterIdentification
      />
    {/key}
  </div>
  {#if result.damaged}
    <ul
      class="noteList caution {$_('config.nameSuffix') === 'Ja' ? 'ja' : 'en'}"
    >
      <li>
        {$_("pages.Tracking.TrackingResult.damagedCaution")}
      </li>
    </ul>
  {/if}
  {#if !result.return && !result.isExistsRedeliveryContext && latestEventStatus !== ShippingStatus.DELIVERED && result.desiredDate && beforeToday(result.desiredDate, result.desiredTime ? result.desiredTime.substring(2, 4) : "")}
    <ul
      class="noteList caution {$_('config.nameSuffix') === 'Ja' ? 'ja' : 'en'}"
    >
      <li>
        {$_("pages.Tracking.TrackingResult.delayCaution")}
      </li>
    </ul>
  {/if}
  {#if result.redeliveryContext && latestEventStatus !== ShippingStatus.DELIVERED}
    <ul
      class="noteList caution {$_('config.nameSuffix') === 'Ja' ? 'ja' : 'en'}"
    >
      {#if result.redeliveryContext.redeliveryDatetimeSpecMethod === RedeliveryDatetimeSpecMethod.AVAILABLE_RECEIVE_TIME_ZONE_OF_WEEK}
        <li>
          {$_("pages.Tracking.TrackingResult.redeliveryCaution2")}
        </li>
      {/if}
      <li>
        {$_("pages.Tracking.TrackingResult.redeliveryCaution1")}
      </li>
    </ul>
  {/if}
  <div class="eventsArea">
    {#each result.events as event, i}
      {#if !result.return || (result.return && event.status !== ShippingStatus.DELIVERED)}
        <TrackingEvent
          {event}
          isLatestEvent={result.return ? false : i == result.events.length - 1}
        />
      {/if}
    {/each}
    {#if result.return}
      <TrackingEvent returnInfo={result.return} isLatestEvent={true} />
    {/if}
  </div>
</div>

<style lang="scss">
  .caption {
    width: 100%;
    background-color: #018786;
  }
  .captionText {
    color: white;
    font-weight: bold;
    line-height: 2;
  }
  .trackingInfo {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #bdbdbd87;
    margin-top: -0.5px;
    margin-bottom: 20px;

    :global(.svg-icons-alert-outline),
    :global(.svg-icons-camera),
    :global(.svg-icons-archive-lock-open),
    :global(.svg-icons-map-marker-radius) {
      fill: #fff;
    }
  }
  .troubleInfo {
    line-height: 1.5;
    background-color: #c4191933;
  }
  .latestStatusArea {
    margin-bottom: 8px;
  }
  .item {
    width: 100%;
  }
  .itemName1 {
    text-align: center;
    background-color: #01878770;
  }
  .itemName2 {
    text-align: center;
    background-color: #01878770;
  }
  .itemInfo1 {
    font-weight: bold;
    background-color: #01878718;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .itemInfo2 {
    font-weight: bold;
    background-color: #01878718;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .infoButtonArea {
    display: flex;
    align-items: end;
    justify-content: center;
  }
  .changeButton {
    position: relative;
    text-align: center;
    background: #018786;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  .changeButton:hover,
  .changeButton:focus {
    filter: drop-shadow(1px 1px 1px rgb(170, 170, 170));
  }
  .hideButton {
    position: absolute;
    opacity: 0;
  }
  .funcTitle1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .funcTitle2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .funcTitle1 .material-icons,
  .funcTitle2 .material-icons {
    margin-right: 5px;
  }
  .packageDropPlaceInfoBtn {
    border: none;
    margin: 0 3px;
    padding: 0;
  }
  .material-icons.mainColor {
    color: #018786;
  }
  .noteList {
    line-height: 1.4;
    margin: -4px 0 8px;
  }
  .noteList li {
    list-style: none;
  }
  .noteList.ja li {
    margin-left: 2em;
    text-indent: -2em;
  }
  .noteList.en li {
    margin-left: 0.6em;
    text-indent: -0.6em;
  }
  .caution {
    color: red;
    font-size: 12px;
  }
  @media screen and (min-width: 810px) {
    .caption {
      margin-top: 20px;
      height: 48px;
      min-width: 300px;
      border-radius: 10px 10px 0px 0px;
    }
    .captionText {
      font-size: 20px;
      padding: 5px 15px;
    }
    .trackingInfo {
      min-width: 300px;
      border-radius: 0px 0px 10px 10px;

      :global(.svg-icons-alert-outline) {
        height: 30px;
      }
      :global(.svg-icons-camera),
      :global(.svg-icons-archive-lock-open),
      :global(.svg-icons-map-marker-radius) {
        height: 20px;
        margin-top: -5px;
        margin-right: 8px;
      }
    }
    .troubleArea {
      margin: 15px 40px -10px 40px;
      display: flex;
    }
    .troubleInfo {
      width: 100%;
      padding: 10px 15px;
      font-size: 14px;
      border-radius: 10px;
    }
    .latestStatusArea {
      padding: 20px 37px 0;
      display: flex;
    }
    .item {
      display: flex;
      flex-flow: column;
    }
    .itemName1 {
      margin: 0px 3px;
      padding: 10px 0px;
      border-radius: 10px 10px 0px 0px;
      font-size: 15px;
    }
    .itemName2 {
      margin: 0px 3px;
      padding: 10px 0px;
      border-radius: 10px 10px 0px 0px;
      font-size: 15px;
    }
    .itemInfo1 {
      margin: 0px 3px;
      padding: 16px;
      font-size: 21px;
      border-radius: 0px 0px 10px 10px;
      flex-grow: 1;
      min-height: 58px;
    }
    .itemInfo2 {
      margin: 0px 3px;
      padding: 16px;
      font-size: 21px;
      border-radius: 0px 0px 10px 10px;
      flex-grow: 1;
      min-height: 58px;
    }
    .changeButton {
      height: 33px;
      width: 160px;
      font-size: small;
      line-height: 0.8;
      border-radius: 7px 7px 7px 7px;
    }
    .bigSizeButton {
      height: 45px;
      width: 200px;
      font-size: medium;
      border-radius: 7px 7px 7px 7px;
    }
    .adjustment1 {
      height: 8px;
    }
    .eventsArea {
      padding: 0px 40px 20px 40px;
    }
    .packageDropPlaceInfoBtn {
      padding-bottom: 1px;
    }
    .packageDropPlaceInfoBtn:hover {
      cursor: pointer;
    }
    .noteList {
      padding: 0 45px;
    }
  }
  @media screen and (max-width: 809px) {
    .caption {
      margin-top: 15px;
      height: 40px;
      min-width: 150px;
      border-radius: 8px 8px 0px 0px;
    }
    .captionText {
      font-size: 15px;
      padding: 6px 12px;
    }
    .trackingInfo {
      min-width: 150px;
      border-radius: 0px 0px 8px 8px;

      :global(.svg-icons-alert-outline) {
        height: 20px;
        margin: 0px 3px;
      }
      :global(.svg-icons-camera),
      :global(.svg-icons-archive-lock-open),
      :global(.svg-icons-map-marker-radius) {
        height: 18px;
        margin-top: -5px;
        margin-right: 6px;
      }
    }
    .troubleArea {
      margin: 15px 15px -8px 15px;
      display: column;
    }
    .troubleInfo {
      padding: 3px 14px 12px 14px;
      font-size: 13px;
      border-radius: 10px;
    }
    .latestStatusArea {
      padding: 12px 15px 0;
    }
    .item {
      display: flex;
    }
    .itemName1 {
      width: 35%;
      margin: 3px 0px;
      padding: 13px 0px;
      border-radius: 10px 0px 0px 10px;
      font-size: 13px;
      display: flex;
      align-items: center;
      flex-flow: column;
      justify-content: center;
    }
    .itemName2 {
      width: 35%;
      margin: 3px 0px;
      border-radius: 10px 0px 0px 10px;
      font-size: 13px;
      display: flex;
      align-items: center;
      flex-flow: column;
      justify-content: center;
    }
    .itemInfo1 {
      width: 65%;
      margin: 3px 0px;
      padding: 13px 5px;
      font-size: 17px;
      border-radius: 0px 10px 10px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .itemInfo2 {
      width: 65%;
      margin: 3px 0px;
      padding: 10px 5px;
      font-size: 17px;
      line-height: 1.2;
      border-radius: 0px 10px 10px 0px;
    }
    .changeButton {
      height: 29px;
      width: 140px;
      font-size: 11px;
      line-height: 0.9;
      border-radius: 6px 6px 6px 6px;
    }
    .bigSizeButton {
      height: 35px;
      width: 160px;
      font-size: 14px;
      border-radius: 6px 6px 6px 6px;
    }
    .adjustment1 {
      height: 4px;
    }
    .eventsArea {
      padding: 10px 15px 10px 15px;
    }
    .noteList {
      padding: 0 20px;
    }
  }
</style>
