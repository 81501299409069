<script>
  import Button, { Label } from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import FormField from "@smui/form-field";
  import { HTTPError } from "ky";
  import { _, locale } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { NO_PICKUP_TIMEFRAME } from "~/libs/commonTypes";
  import { formatDate } from "~/libs/dateUtils";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";
  import { formatTrackingNumber } from "~/libs/utils";

  /** @type {string} */
  export let trackingNumber;

  /** @type {import("~/libs/commonTypes").TrackingResult} */
  export let result;

  /**
   * 再配達希望日時の表示を切り替える関数
   * @type {Function}
   */
  export let configureLocaleSpecificFormat;

  /**
   * 選択肢として表示する日数
   * @type {number}
   */
  const DATE_NUM = 7;

  /**
   * モーダルの開閉状況
   * @type {boolean}
   */
  let open;

  /**
   * @typedef {{
   *   timeFrame: string,
   *   availability: boolean
   * }} TimeFrameSet 時間帯の選択肢
   * @typedef {{
   *   date: Date,
   *   timeFrameSetList: Array<TimeFrameSet>
   * }} DateAndTimeFrameSet 日付と時間帯の選択肢
   */

  /**
   * 選択肢として表示する日時のリスト
   * @type {Array<DateAndTimeFrameSet>}
   */
  let dateAndTimeFrameList = [];
  for (let i = 0; i < DATE_NUM; i++) {
    let date = new Date();
    date.setDate(date.getDate() + i);

    /** @type {Array<TimeFrameSet>} */
    let timeFrameList = [];
    for (let j = 0; j < result.redeliveryContext.timeFramePreset.length; j++) {
      timeFrameList[j] = {
        timeFrame: result.redeliveryContext.timeFramePreset[j],
        availability: isUnavailable(
          date,
          result.redeliveryContext.timeFramePreset[j],
        ),
      };
    }

    dateAndTimeFrameList[i] = {
      date: date,
      timeFrameSetList: timeFrameList,
    };
  }

  /**
   * 変更前の全時間帯での受け取り不可状態
   * 全ての時間帯で受け取りができない場合にtrue
   * @type {boolean}
   */
  let currentIsNotAnavailableAllTimeFrame =
    result.specifiedPickupDatetime?.desiredRedeliveryDatetime?.timeFrame ===
    NO_PICKUP_TIMEFRAME
      ? true
      : false;

  /**
   * 変更後の全時間帯での受け取り不可状態
   * 全ての時間帯で受け取りができない場合にtrue
   * @type {boolean}
   */
  let newIsNotAnavailableAllTimeFrame =
    result.specifiedPickupDatetime?.desiredRedeliveryDatetime?.timeFrame ===
    NO_PICKUP_TIMEFRAME
      ? true
      : false;

  /**
   * 再配達の希望日時。「日付,時間帯」の形式
   * @type {string}
   */
  let desiredDateTime = result.specifiedPickupDatetime
    ?.desiredRedeliveryDatetime
    ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date +
      "," +
      result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
    : ",";

  /**
   * 変更前の再配達の希望日
   * @type {string}
   */
  let currentSpecifiedDate = result.specifiedPickupDatetime
    ?.desiredRedeliveryDatetime
    ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date
    : "";

  /**
   * 変更前の再配達の希望時間帯
   * @type {string}
   */
  let currentSpecifiedTime = result.specifiedPickupDatetime
    ?.desiredRedeliveryDatetime
    ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
    : "";

  /**
   * 変更後の再配達の希望日
   * @type {string}
   */
  $: newSpecifiedDate = desiredDateTime.split(",")[0];

  /**
   * 変更後の再配達の希望時間帯
   * @type {string}
   */
  $: newSpecifiedTime = desiredDateTime.split(",")[1];

  /**
   * 未入力の項目がある場合にtrue
   * @type {boolean}
   */
  let notAllEnteredCheck = false;

  /** @type {string} 荷受人氏名 */
  let name = sessionStorage.getItem("identificationName") ?? "";
  /** @type {string} 荷受人郵便番号 */
  let postcode = sessionStorage.getItem("identificationPostcode") ?? "";
  /** @type {string} 荷受人電話番号 */
  let tel = sessionStorage.getItem("identificationTel") ?? "";

  /**
   * ダイアログを開く。
   */
  export function openDialog() {
    newIsNotAnavailableAllTimeFrame =
      result.specifiedPickupDatetime?.desiredRedeliveryDatetime?.timeFrame ===
      NO_PICKUP_TIMEFRAME
        ? true
        : false;
    desiredDateTime = result.specifiedPickupDatetime?.desiredRedeliveryDatetime
      ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date +
        "," +
        result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
      : ",";
    currentSpecifiedDate = result.specifiedPickupDatetime
      ?.desiredRedeliveryDatetime
      ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date
      : "";
    currentSpecifiedTime = result.specifiedPickupDatetime
      ?.desiredRedeliveryDatetime
      ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
      : "";

    open = true;
  }
  function closeDialog() {
    notAllEnteredCheck = false;
    desiredDateTime = ",";
    newIsNotAnavailableAllTimeFrame = false;
    open = false;
  }

  /**
   * 配送希望時間をフォーマットする
   * @param {string} timeFrame 数字4桁（開始時間2桁、終了時間2桁）
   * @param {import("svelte-i18n").locale} locale svelte-i18nのlocale
   * @returns {string} 時間指定の表示
   */
  function formatTimeFrame(timeFrame, locale) {
    let startTime = Number(timeFrame.substring(0, 2));
    let endTime = Number(timeFrame.substring(2, 4));

    let str = "";

    if (locale === "ja") {
      str = startTime + "時～" + endTime + "時";
    } else {
      let startTimeZone = "AM";
      let endTimeZone = "AM";

      if (startTime > 12) {
        startTime = startTime - 12;
        startTimeZone = "PM";
      }
      if (endTime > 12) {
        endTime = endTime - 12;
        endTimeZone = "PM";
      }

      str =
        startTime +
        ":00 " +
        startTimeZone +
        " - " +
        endTime +
        ":00 " +
        endTimeZone;
    }

    return str;
  }

  /**
   * 指定された日付、および時間帯の終了時刻が現時刻より前かを判定する
   * @param {Date} date
   * @param {string} timeFrame 数字4桁（開始時間2桁、終了時間2桁）
   * @returns {boolean} 指定された日付、および時間帯の終了時刻が現時刻より前であればtrue
   */
  function isBeforeNow(date, timeFrame) {
    const endTime = Number(timeFrame.substring(2, 4));
    let formatedDate = date;

    if (endTime === 0) {
      formatedDate.setHours(23, 59);
    } else {
      formatedDate.setHours(endTime);
    }

    return formatedDate < new Date();
  }

  /**
   * 指定された日付、および時間帯が配達不可の時間帯かを判定する
   * @param {Date} date
   * @param {string} timeFrame 数字4桁（開始時間2桁、終了時間2桁）
   * @returns {boolean} 指定された日付、および時間帯が宅配ドライバーに配達不可に指定されていたらtrue
   */
  function isUnavailable(date, timeFrame) {
    if (!result.redeliveryContext.redeliveryUnavailability) {
      return false;
    }

    let isMatch = false;
    result.redeliveryContext.redeliveryUnavailability.forEach(
      (dateAndTimeFrame) => {
        if (
          date.toDateString() ===
            new Date(dateAndTimeFrame.date).toDateString() &&
          timeFrame === dateAndTimeFrame.timeFrame
        ) {
          isMatch = true;
        }
      },
    );

    return isMatch;
  }

  /**
   * 再配達の希望日時を更新する
   */
  function updateDesiredDateTime() {
    if (
      !newIsNotAnavailableAllTimeFrame &&
      newSpecifiedDate === "" &&
      newSpecifiedTime === ""
    ) {
      notAllEnteredCheck = true;
      // 未選択がある場合はモーダルを閉じない
      this.setAttribute("data-mdc-dialog-action", "");
    } else {
      notAllEnteredCheck = false;
      this.setAttribute("data-mdc-dialog-action", "close");

      if (
        currentSpecifiedDate !== newSpecifiedDate ||
        currentSpecifiedTime !== newSpecifiedTime ||
        (currentIsNotAnavailableAllTimeFrame && newIsNotAnavailableAllTimeFrame)
      ) {
        // 変更がある場合
        if (newIsNotAnavailableAllTimeFrame) {
          let date = new Date();
          date.setDate(date.getDate() + 6);

          newSpecifiedDate = formatDate(date, "yyyy-MM-dd", $locale);
          newSpecifiedTime = NO_PICKUP_TIMEFRAME;
        }

        /** @type {import("~/libs/commonTypes").DateAndTimeFrame} */
        const newDesiredDateAndTimeFrame = {
          date: newSpecifiedDate,
          timeFrame: newSpecifiedTime,
        };
        /** @type {import("~/libs/commonTypes").SpecifiedPickupDatetime} */
        const newSpecifiedPickupDatetime = {
          desiredRedeliveryDatetime: newDesiredDateAndTimeFrame,
          availablePickupDatetime: [],
        };
        /** @type {import("~/libs/backendApi").PushNotificationMessage} */
        const message = {
          title: $_("push.updatedRedeliveryDatetime.title"),
          body: $_("push.updatedRedeliveryDatetime.body", {
            values: {
              trackingNumber: formatTrackingNumber(trackingNumber),
            },
          }),
        };
        /** @type {import("~/libs/backendApi").PushNotification} */
        const pushNotification = {
          title: message.title,
          body: message.body,
          data: {
            message: message,
            trackingNumber: trackingNumber,
            adjustedRedeliveryDatetime: newDesiredDateAndTimeFrame,
          },
        };
        /** @type {import("~/libs/backendApi").ReceiverIdentification} */
        const receiverIdentification =
          name && postcode && tel
            ? {
                name,
                postcode,
                tel,
              }
            : null;
        loadingProgress.wrapAsync(async () => {
          try {
            await backendApi.updateDesiredRedeliveryDatetime(
              trackingNumber,
              newDesiredDateAndTimeFrame,
              pushNotification,
              receiverIdentification,
            );
            result.specifiedPickupDatetime = newSpecifiedPickupDatetime;
            delete result.redeliveryContext.adjustedRedeliveryDatetime;
            configureLocaleSpecificFormat();
            toast.info(
              $_(
                "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.completion",
              ),
            );
            closeDialog();
          } catch (error) {
            showErrorToast(error);
          }
        })();
      } else {
        closeDialog();
      }
    }
  }

  /**
   * エラーメッセージをトーストで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorToast(error) {
    if (error["errorResponse"]?.title === "delivery completed.") {
      toast.error($_("errors.deliveredPackage"));
    } else if (
      error instanceof HTTPError &&
      error.response?.status >= 400 &&
      error.response?.status < 500
    ) {
      console.error(error);
      toast.error($_("errors.invalidDesiredRedeliveryDatetime"));
    } else {
      console.error(error);
      toast.error($_("errors.failedDesiredRedeliveryDatetime"));
    }
  }
</script>

<div class="setDesiredRedeliveryDateDialog">
  <Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="set-desired-redelivery-datetime-dialog-title"
    aria-describedby="set-desired-redelivery-datetime-dialog-content"
    style="margin-top: 50px; max-height: 90%"
  >
    <Title id="set-desired-redelivery-datetime-dialog-title"
      >{$_("pages.Tracking.SetDesiredRedeliveryDatetimeDialog.title")}</Title
    >
    <Content id="set-desired-redelivery-datetime-dialog-content">
      <p>
        {@html $_(
          "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.description",
        )}
      </p>
      <div class="calendar">
        {#each dateAndTimeFrameList as dateAndTimeFrame}
          <div class="calendarDate">
            <div class="head">
              {#if new Date(dateAndTimeFrame.date).toDateString() === new Date().toDateString()}
                {$_("pages.Tracking.SetDesiredRedeliveryDatetimeDialog.today")}
              {/if}
              {formatDate(
                dateAndTimeFrame.date,
                $_("config.defaultDateFormat"),
                $locale,
              )}
            </div>
            {#each dateAndTimeFrame.timeFrameSetList as timeFrameSet}
              <label class="timeFrame">
                <input
                  type="radio"
                  name="desiredDateTime"
                  bind:group={desiredDateTime}
                  value={formatDate(
                    dateAndTimeFrame.date,
                    "yyyy-MM-dd",
                    $locale,
                  ) +
                    "," +
                    timeFrameSet.timeFrame}
                  disabled={newIsNotAnavailableAllTimeFrame
                    ? true
                    : isBeforeNow(
                        dateAndTimeFrame.date,
                        timeFrameSet.timeFrame,
                      ) || timeFrameSet.availability}
                />
                <div class="timeFrameInner">
                  {formatTimeFrame(timeFrameSet.timeFrame, $locale)}
                </div>
              </label>
            {/each}
          </div>
        {/each}
      </div>
      <div class="noPickupTimeFrameArea">
        <FormField>
          <Checkbox bind:checked={newIsNotAnavailableAllTimeFrame} />
          <span slot="label"
            >{$_(
              "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.notAvailableAllTimeFrameLabel",
            )}</span
          >
        </FormField>
      </div>
      {#if notAllEnteredCheck}
        <p class="notEnteredCaution">
          {$_(
            "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.notEnteredCaution",
          )}
        </p>
      {/if}
    </Content>
    <Actions>
      <Button on:click={closeDialog}>
        <Label
          >{$_(
            "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.cancelButtonLabel",
          )}</Label
        >
      </Button>
      <Button
        on:click={updateDesiredDateTime}
        disabled={(currentIsNotAnavailableAllTimeFrame &&
          newIsNotAnavailableAllTimeFrame) ||
          (!newIsNotAnavailableAllTimeFrame &&
            currentSpecifiedDate === newSpecifiedDate &&
            currentSpecifiedTime === newSpecifiedTime)}
      >
        <Label
          >{$_(
            "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.updateButtonLabel",
          )}</Label
        >
      </Button>
    </Actions>
  </Dialog>
</div>

<style lang="scss">
  p {
    color: #000;
  }
  .calendar {
    box-sizing: border-box;
    color: #000;
    display: flex;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    width: 100%;
  }
  .calendarDate .head {
    background-color: #018786;
    border: 1px solid #018786;
    color: #fff;
  }
  .calendarDate .timeFrame {
    display: block;
    border-right: 1px solid #018786;
    border-bottom: 1px solid #018786;
    position: relative;
  }
  .calendarDate .timeFrame:hover {
    cursor: pointer;
  }
  .calendarDate .timeFrame input[type="radio"] {
    position: absolute;
    left: -1000%;
  }
  .calendarDate .timeFrame .timeFrameInner {
    padding: 10px 5px;
  }
  .calendarDate .timeFrame input[type="radio"]:disabled + .timeFrameInner {
    background-color: #d7d7d7;
    color: #6b6b6b;
  }
  .calendarDate .timeFrame input[type="radio"]:checked + .timeFrameInner {
    background-color: #eae2f0;
  }
  .calendarDate
    .timeFrame
    input[type="radio"]:checked:disabled
    + .timeFrameInner {
    background-color: #d7d7d7;
  }
  .noPickupTimeFrameArea {
    margin-top: 10px;

    :global(.mdc-checkbox + label:hover) {
      cursor: pointer;
    }
  }
  .notEnteredCaution {
    color: #f00;
    margin-top: 20px;
  }

  @media screen and (min-width: 810px) {
    .setDesiredRedeliveryDateDialog {
      :global(.mdc-dialog .mdc-dialog__surface) {
        max-width: 800px;
      }
    }
    .calendarDate {
      width: 100px;
    }
    .calendarDate:not(:first-of-type) .head {
      border-left: none;
    }
    .calendarDate:first-of-type .timeFrame {
      border-left: 1px solid #018786;
    }
  }

  @media screen and (max-width: 809px) {
    .calendar {
      flex-flow: column;
    }
    .calendarDate:not(:first-of-type) {
      margin-top: 10px;
    }
    .calendarDate .timeFrame {
      border-left: 1px solid #018786;
    }
    .calendarDate .timeFrame .timeFrameInner {
      padding: 5px 2px;
    }
  }
</style>
